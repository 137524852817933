<template>
  <div class="card" style="margin-top:0;">
    <div class="card-header card-header-icon card-header-rose">
      <div class="card-icon">
        <i class="material-icons">notes</i>
      </div>
      <h3 class="card-title">Ingreso de Costos</h3>
    </div>
    <div class="card-body">
      <v-row class="mx-0">
        <v-col cols="1">
          <v-select 
            v-model="gasto.year"
            :items="years"
            item-text="nombre"
            item-value="id"
            label="Año Presupuesto"
            color="blue darken-3"
            return-object
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select 
            v-model="gasto.centro_costo"
            :items="centro_costos"
            item-text="nombre"
            item-value="id"
            label="Centro Costo"
            color="blue darken-3"
            return-object
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select 
            v-model="gasto.tipo_costo"
            :items="tipo_costos"
            item-text="nombre"
            item-value="id"
            label="Tipo"
            color="blue darken-3"
            return-object
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-text-field v-model="gasto.nombre" label="Nombre del presupuesto"></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-select 
            v-model="gasto.moneda"
            :items="monedas"
            item-text="nombre"
            item-value="id"
            label="Moneda"
            color="blue darken-3"
            return-object
          ></v-select>
        </v-col>
        <v-col cols="1">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                color="blue darken-1"
                dark
                right
                fab
                small
                @click="grabar"
                v-bind="attrs"
                v-on="on"
              >
                <i class="material-icons">save</i>
              </v-btn>
            </template>
            <span>Grabar Encabezado Presupuesto</span>
          </v-tooltip>
        </v-col>
        <v-col cols="1" v-if="canUpload">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                color="green darken-1"
                dark
                right
                fab
                small
                @click="upload = true"
                v-bind="attrs"
                v-on="on"
              >
                <i class="material-icons">upload</i>
              </v-btn>
            </template>
            <span>Realizar Carga Masiva</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-simple-table
        dense
        class="mx-2 my-2 elevation-3 text-center"
        no-data-text="No hay datos de gasto"
        :search="search"
        :header-props="{ sortIcon: 'arrow_upward' }"
        fixed-header
        :height="table_height"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center blue-grey darken-4 white-text">Agrupación de cuentas</th>
              <th class="text-center blue-grey darken-4 white-text">Código Agrupación</th>
              <th class="text-center blue-grey darken-4 white-text">Código Subcosto</th>
              <th class="text-center blue-grey darken-4 white-text">Subcosto</th>
              <th class="text-center blue-grey darken-4 white-text">Código Artículo</th>
              <th class="text-center blue-grey darken-4 white-text">Descripción Artículo</th>
              <th class="text-center blue-grey darken-4 white-text">Moneda</th>
              <th class="text-center blue-grey darken-4 white-text" v-for="header in costosListHeaders" v-bind:key="header.number">
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(articulo, index) in costosListBody" :key="index" :class="{rowTotal: articulo.codigo == 'Total'}">
              <td class="text-left">{{ articulo.cuenta.nombre }}</td>
              <td class="text-left">{{ articulo.cuenta.codigo }}</td>
              <td class="text-left">{{ articulo.subcosto.codigo }}</td>
              <td class="text-left">{{ articulo.subcosto.nombre }}</td>
              <td class="text-left">{{ articulo.codigo }}</td>
              <td class="text-left">{{ articulo.nombre }}</td>
              <td class="text-left">{{ articulo.moneda }}</td>
              <td v-for="(header, m) in costosListHeaders" v-bind:key="header.number">
                <v-text-field
                  class="txt-number"
                  v-model="articulo['m_' + (m + 1)]"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <h4 class="card-title">Lista de presupuestos</h4>
      <v-data-table
        :items="presupuestos"
        :headers="encabezados"
        dense
        class="mx-2 my-2 elevation-3 text-center"
        no-data-text="No hay datos de presupuestos"
        :header-props="{ sortIcon: 'arrow_upward' }"
        fixed-header
      >
        <template v-slot:[`item.acciones`]="{item}">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon
                medium
                @click="mostrarPresupuesto(item)"
                v-on="on"
              >
                edit
              </v-icon>
            </template>
            <span>Cargar presupuesto</span>
          </v-tooltip>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon
                medium
                @click="borrarPresupuesto(item.id)"
                v-on="on"
              >
                delete
              </v-icon>
            </template>
            <span>Borrar presupuesto</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
    <!-- Modal upload -->
    <v-dialog
      v-model="upload"
      width="600px"
    >
      <v-card>
        <v-card-title class="green darken-1">
          Subir costos desde excel
        </v-card-title>
        <v-card-text>
          <v-row class="mx-2">
            <v-col>
              <v-file-input v-model="file" accept=".xls, .xlsx" label="Archivo Excel"></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="upload = false"
          >Cancelar</v-btn>
          <v-btn
            color="primary"
            @click="uploadCostos"
          >Subir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- / Modal -->
    <v-snackbar
      v-model="snackbar"
      :timeout="5000"
      :color="color"
    >
      {{ validation_message }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
  .table-wellboat{
    font-size: 16pt;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }
  .card{
    margin-top: 0;
  }
  .card .card-header-rose .card-icon{
    background: #263238;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(32, 30, 31, 0.822);
  }
  .txt-number{
    width:100px;
    text-align:right !important;
  }
  .rowTotal{
    background-color: darkgray;
  }
</style>
<script>
  import {mapState, mapMutations} from 'vuex'

  export default {
    data: () => ({
      file: null,
      canUpload: false,
      upload: false,
      years: [],
      color: 'green',
      snackbar: false,
      validation_message: '',
      search: null,
      isLoading: false,
      empresas: [],
      inicio: '',
      termino: '',
      menu1: false,
      menu2: false,
      url_excel: '',
      url_pdf: '',
      table_height: 600,
      cierre: 0,
      cierres: [],
      gasto: {
        year: 2021,
        centro_costo: {},
        nombre: '',
        valor_cambio: 0,
        moneda: {},
        tipo_costo:{}
      },
      semanas: [],
      centro_costos: [],
      costosListHeaders: [],
      costosListBody: [],
      monedas: [],
      tipo_costos: [],
      encabezados: [
        {
          text: '?',
          value: 'acciones'
        },
        {
          text: 'Fecha',
          value: 'fecha'
        },
        {
          text: 'Año',
          value: 'year'
        },
        {
          text: 'Nombre',
          value: 'nombre'
        },
        {
          text: 'Tipo',
          value: 'tipo_costo.nombre'
        },
        {
          text: 'Moneda',
          value: 'moneda.nombre'
        }
      ],
      presupuestos: []
    }),
    mounted() {
      this.loadMonedas();
      this.loadTipoCostos();
      this.loadCentroCostos();
      this.loadPresupuestos();
      if(window.innerHeight < 768){
        this.table_height = 450;
      }
      this.years = [];
      let year = parseInt(this.moment().format('YYYY'));
      for(let y = year; y <= (year + 10); y++){
        this.years.push({id: y, nombre: y});
      }
      this.gasto.year = year;
      let months = [
        {id:1, nombre: 'Ene'}, 
        {id:1, nombre: 'Feb'}, 
        {id:1, nombre: 'Mar'}, 
        {id:1, nombre: 'Abr'}, 
        {id:1, nombre: 'May'}, 
        {id:1, nombre: 'Jun'}, 
        {id:1, nombre: 'Jul'}, 
        {id:1, nombre: 'Ago'}, 
        {id:1, nombre: 'Sep'}, 
        {id:1, nombre: 'Oct'}, 
        {id:1, nombre: 'Nov'}, 
        {id:1, nombre: 'Dic'}
      ];
      this.costosListHeaders = months.map(m => {
        return {
          text: m.nombre + '-' + this.moment().format('YYYY'),
          value: 'm_' + m.id,
          width: "120px",
          sortable: false
        };
      })
    },
    methods:{
      async mostrarPresupuesto(item){
        this.gasto = item
        this.getPresupuesto(item.id)
      },
      async loadCentroCostos(){
        this.centro_costos = this.$store.getters['centro_costo/getActive'];
        this.gasto.centro_costo = this.centro_costos[0];
      },
      async loadMonedas(){
        let url = `${this.base_url}monedas`;
        await this.axios.get(url, this.headers).then((response)=>{
          this.monedas = response.data;
          this.gasto.moneda = this.monedas[0];
        }).catch((error)=>{
          console.log(error);
        });
      },
      async loadPresupuestos(){
        let url = `${this.base_url}costos`;
        await this.axios.get(url, this.headers).then((response)=>{
          this.presupuestos = response.data;
        }).catch((error)=>{
          console.log(error);
        });
      },
      async loadTipoCostos(){
        let url = `${this.base_url}tipo_costos`;
        await this.axios.get(url, this.headers).then((response)=>{
          this.tipo_costos = response.data;
          this.gasto.tipo_costo = this.tipo_costos[0];
        }).catch((error)=>{
          console.log(error);
        });
      },
      async getPresupuesto(id){
        this.costosListBody = [];
        let url = `${this.base_url}costos/por_presupuesto/${id}`;
        await this.axios.get(url, this.headers).then((response)=>{
          this.costosListBody = response.data;
          this.url_excel = this.storage_url + response.data.url_excel;
          this.url_pdf   = this.storage_url + response.data.url_pdf;
          this.showSnackBar(false);
        }).catch((error)=>{
          console.log(error);
        });
      },
      async borrarPresupuesto(id){
        this.$confirm('Desea borrar este presupuesto?').then(res => {
          if(res){
            let url = `${this.base_url}costos/${id}`;
            this.axios.delete(url, this.headers).then((response)=>{
              console.log(response);
              this.loadPresupuestos();
              this.showSnackBar(false);
            }).catch((error)=>{
              this.validation_message = error;
              this.showSnackBar(true);
            });
          }
        });
      },
      async grabar(){
        let data = {
            nombre: this.gasto.nombre,
            tipo_costos_id: this.gasto.tipo_costo.id,
            centro_costos_id: this.gasto.centro_costo.id,
            monedas_id: this.gasto.moneda.id,
            fecha: this.moment().format('YYYY-MM-DD'),
            year: this.gasto.year,
        };
        let url = `${this.base_url}costos`;
        await this.axios.post(url, data, this.headers).then(resp => {
          this.gasto.id = resp.data.id;
          console.log(this.gasto);
          this.canUpload = true;
        });
      },
      async uploadCostos(){
        this.headers.headers['Content-Type'] = "multipart/form-data";
        let url = `${this.base_url}costos/upload/excel`;
        let formData = new FormData();
        formData.append("excel", this.file);
        formData.append("costos_id", this.gasto.id);
        let response = null; 
        try{
          response = await this.axios.post(url, formData, this.headers);
          this.file = null;
          this.upload = false;
          //this.getPresupuesto();
          this.showSnackBar(false);
        }catch(error){
          response = error.response;
          let msg = response.data.data;
          this.validation_message = response.status + ': ' + response.data.message + '. '+ msg;
          this.showSnackBar(true);
        }
      },
      download(){
        window.open(this.url_excel);
      },
      downloadPDF(){
        window.open(this.url_pdf);
      },
      showSnackBar(error){
        if(error){
          this.color = 'red';
        }else{
          this.color = 'green';
          this.validation_message = "Ejecutado exitosamente!";
        }
        this.snackbar = true;
      },
      ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}`
      },
      formatIso(fecha){
        let arrF = fecha.split('-');
        return `${arrF[2]}-${arrF[1]}-${arrF[0]}`;
      },
      formatNumber(numero, decimales = 2){ //, checknegative = false){
        //if(checknegative && numero < 0) return 'Número Inválido';
        let arrNum = numero.toString().split('.');
        if(arrNum[1] == undefined) arrNum[1] = "";
        let num = arrNum[0];
        if(!isNaN(num)){
          num = num.split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g,'$1.');
          num = num.split('').reverse().join('').replace(/^[.]/,'');
          if(decimales > 0) num += ',';
          return num + arrNum[1].padEnd(decimales, '0');
        }else{
          return '0';
        }
      },
    },
    computed:{
      ...mapState(['storage_url', 'loading', 'base_url', 'headers', 'user']),
      inicio_formated () {
        return this.formatDate(this.inicio)
      },
      termino_formated () {
        return this.formatDate(this.termino)
      },
    },
    components:{
    }
  }
</script>